import React, { useState, useContext } from "react";
import { LanguageContext } from "../functions/LanguageContext";

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState("");

  const { language } = useContext(LanguageContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can perform API calls for authentication.
    // For now, let's assume the login is successful.
    onLogin(username);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder={language === "th" ? "ชื่อผู้ใช้" : "Username"}
        value={username}
        required
        minLength={3}
        onChange={(e) => setUsername(e.target.value)}
      />
      <label>
        <input type="checkbox" id="agreement" required></input>
        {language === "th"
          ? "ฉันยอมรับในเงื่อนไขการบริการความอร่อยและการจัดส่งคุกกี้ถึงบ้านคุณ อิอิ ><"
          : "I agree the term of delicious services and cookies delivery to your house."}
      </label>
      <button type="submit">
        {language === "th" ? "ลงชื่อเข้าใช้" : "Login"}
      </button>
    </form>
  );
};

export default LoginForm;
