import React, { useContext, useEffect, useState, useRef } from "react";
import AppearanceContext from "./AppearanceContext";
import "../css/appearance.css";
import { LanguageContext } from "./LanguageContext";
import ThemeContext from "./ThemeContext";

const RightItems = () => {
  const { language } = useContext(LanguageContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { appearance, changeAppearance } = useContext(AppearanceContext);

  const setBodyClass = () => {
    document.body.className = theme + " " + appearance;
  };

  const [isAOpen, setIsAOpen] = useState(false);
  const [isTOpen, setIsTOpen] = useState(false);
  const dropdownARef = useRef(null);
  const dropdownTRef = useRef(null);

  const handleTDd = (event) => {
    event.stopPropagation();
    setIsTOpen(!isTOpen);
  };

  const handleADd = (event) => {
    event.stopPropagation();
    setIsAOpen(!isAOpen);
  };

  const handleToggleSelectDropdownDefault = () => {
    changeAppearance("default");
  };

  const handleToggleSelectDropdownViolet = () => {
    changeAppearance("violet");
  };

  const handleToggleSelectDropdownSky = () => {
    changeAppearance("sky");
  };

  const handleToggleSelectDropdownOrange = () => {
    changeAppearance("orange");
  };

  const handleToggleSelectDropdownRose = () => {
    changeAppearance("rose");
  };

  const handleToggleSelectDropdownLime = () => {
    changeAppearance("lime");
  };

  const handleToggleSelectDropdownDark = () => {
    changeTheme("dark");
  };

  const handleToggleSelectDropdownLight = () => {
    changeTheme("light");
  };

  // Call the setBodyThemeClass function when the component mounts and whenever the theme changes
  useEffect(() => {
    setBodyClass(theme, appearance);
  });

  const handleAClickOutside = (event) => {
    if (dropdownARef.current && !dropdownARef.current.contains(event.target)) {
      setIsAOpen(false);
    }
  };

  const handleTClickOutside = (event) => {
    if (dropdownTRef.current && !dropdownTRef.current.contains(event.target)) {
      setIsTOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleAClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleAClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleTClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleTClickOutside);
    };
  }, []);

  return (
    <div className="right-items-container">
      <div
        className={`input-group-btn ${
          isAOpen ? "relative" : ""
        } dropdown btn-group ${isAOpen ? "open" : ""}`}
        ref={dropdownARef}
      >
        <button
          id="appearance-switcher"
          aria-haspopup="true"
          aria-expanded={isAOpen}
          type="button"
          title={language === "th" ? "สีของธีม" : "Theme Color"}
          className={`a-theme-btn`}
          onClick={handleADd}
        >
          <i className="fa fa-wand-magic-sparkles"></i>
        </button>
        <div className="dropdown-a-menu" aria-labelledby="appearance-switcher">
          <div>
            <span
              className={`${
                appearance === "default" ? "appearance-active" : ""
              }`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownDefault}
            >
              {language === "th" ? "ค่าเริ่มต้น" : "Default"}
            </span>
          </div>
          <div>
            <span
              className={`${
                appearance === "violet" ? "appearance-active" : ""
              }`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownViolet}
            >
              Violet
            </span>
          </div>
          <div>
            <span
              className={`${appearance === "sky" ? "appearance-active" : ""}`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownSky}
            >
              Sky
            </span>
          </div>
          <div>
            <span
              className={`${appearance === "lime" ? "appearance-active" : ""}`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownLime}
            >
              Lime
            </span>
          </div>
          <div>
            <span
              className={`${
                appearance === "orange" ? "appearance-active" : ""
              }`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownOrange}
            >
              Orange
            </span>
          </div>
          <div>
            <span
              className={`${appearance === "rose" ? "appearance-active" : ""}`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownRose}
            >
              Rose
            </span>
          </div>
        </div>
      </div>
      <div
        className={`input-group-btn ${
          isTOpen ? "relative" : ""
        } dropdown btn-group ${isTOpen ? "open" : ""}`}
        ref={dropdownTRef}
      >
        <button
          id="theme-switcher"
          aria-haspopup="true"
          aria-expanded={isTOpen}
          type="button"
          title={language === "th" ? "โหมดของธีม" : "Theme Mode"}
          className={`t-theme-btn`}
          onClick={handleTDd}
        >
          <i className="fa fa-brush"></i>
        </button>
        <div className="dropdown-t-menu" aria-labelledby="theme-switcher">
          <div>
            <span
              className={`${theme === "dark" ? "appearance-active" : ""}`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownDark}
            >
              {language === "th" ? "มืด" : "Dark"}
            </span>
          </div>
          <div>
            <span
              className={`${theme === "light" ? "appearance-active" : ""}`}
              tabIndex="-1"
              onClick={handleToggleSelectDropdownLight}
            >
              {language === "th" ? "สว่าง" : "Light"}
            </span>
          </div>
        </div>
      </div>
      <a
        className={"tiktok-btn"}
        title="TikTok"
        href={"https://www.tiktok.com/@socute_whitebear"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="button-ico">
          <i className="fab fa-tiktok"></i>
        </div>
      </a>
    </div>
  );
};

export default RightItems;
