// privacy.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import Timestamp from "../functions/Timestamp";

function PrivacyPolicy() {
  const { language } = useContext(LanguageContext);
  const lastUpdatedTimestamp = 1692982800;
  return (
    <>
      <Helmet>
        <title>
          {language === "th" ? "ความเป็นส่วนตัว" : "Privacy Policy"} - CuteBear
        </title>
      </Helmet>
      <div className="container">
        <h2 className="text-left title">
          {language === "th"
            ? "นโยบายความเป็นส่วนตัวของเว็บไซต์นี้"
            : "Privacy Policy for this website"}
        </h2>
        <span className="text-left x-prv">
          <i className="fa fa-clock margin-right-5px"></i>
          {language === "th"
            ? `อัพเดตล่าสุด: 26 ส.ค. 2566`
            : `Last Updated: Aug 26, 2023`}{" "}
          (
          <Timestamp
            epochTimestamp={lastUpdatedTimestamp}
            timezone="Asia/Bangkok"
          />
          )
        </span>
        <div className="items-container">
          <div className="text-items">
            <p>
              <i className="fa fa-circle-up margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "เราใช้คุกกี้เพื่อปรับปรุงประสบการณ์ของคุณบนเว็บไซต์ของเรา"
                : "We use cookies to improve your experience on our website."}
            </p>
            <p>
              <i className="fa fa-box-archive margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "คุกกี้จัดเก็บธีม ภาษา และข้อมูลอื่น ๆ ของคุณบนเว็บไซต์นี้"
                : "The cookies store your themes, languages, and other data on this website."}
            </p>
            <p>
              <i className="fa fa-circle-info margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "เราไม่สามารถเข้าถึงข้อมูลของคุณบนเว็บไซต์นี้ได้"
                : "We cannot have access to your data on this website."}
            </p>
            <p>
              <i className="fa fa-trash margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "ข้อมูลคุกกี้ของคุณจะถูกบันทึกและลบภายใน 14 วัน"
                : "Your cookies data is saved and deleted within 14 days."}
            </p>
            <p>
              <i className="fa fa-location-dot margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "เว็บไซต์นี้ไม่สามารถติดตามตำแหน่งของคุณหรือข้อมูลใด ๆ ของคุณได้"
                : "This website cannot track your location or any of your data."}
            </p>
            <p>
              <i className="fa fa-circle-xmark margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "เราไม่สามารถสร้างหรือลบข้อมูลของคุณบนเว็บไซต์นี้ได้"
                : "We cannot create or delete your data on this website."}
            </p>
            <p>
              <i className="fa fa-shield margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "ข้อมูลคุกกี้ของคุณปลอดภัยและได้รับการปกป้องโดยเบราว์เซอร์ของคุณ"
                : "Your cookies data is safe and protected by your browser."}
            </p>
            <p>
              <i className="fa fa-envelope margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "เราจะไม่แบ่งปันอีเมลของคุณกับบุคคลอื่น"
                : "We will not share your email with anyone else."}
            </p>{" "}
            <p>
              <i className="fa fa-address-card margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "ข้อมูลส่วนบุคคลของคุณจะปลอดภัยจากบุคคลอื่น และจะไม่มีการแบ่งปันข้อมูลนี้ให้กับบุคคลอื่น"
                : "Your personal information will be safe from others. And will not be shared with anyone else."}
            </p>
          </div>
        </div>
        <h1 className="text-left">
          *
          {language === "th"
            ? "นโยบายนี้อาจมีการเปลี่ยนแปลง"
            : "This policy is subject to change."}
        </h1>
      </div>
    </>
  );
}

export default PrivacyPolicy;
