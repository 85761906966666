// privacy.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import Timestamp from "../functions/Timestamp";

function Terms() {
  const { language } = useContext(LanguageContext);
  const lastUpdatedTimestamp = 1692982800;
  return (
    <>
      <Helmet>
        <title>
          {language === "th" ? "เงื่อนไขการให้บริการ" : "Terms of Service"} -
          CuteBear
        </title>
      </Helmet>
      <div className="container">
        <h2 className="text-left title">
          {language === "th" ? "เงื่อนไขการให้บริการ" : "Terms of Service"}
        </h2>
        <span className="text-left x-prv">
          <i className="fa fa-clock margin-right-5px"></i>
          {language === "th"
            ? `อัพเดตล่าสุด: 26 ส.ค. 2566`
            : `Last Updated: Aug 26, 2023`}{" "}
          (
          <Timestamp
            epochTimestamp={lastUpdatedTimestamp}
            timezone="Asia/Bangkok"
          />
          )
        </span>
        <div className="items-container">
          <div className="text-items">
            <p>
              <i className="fa fa-circle-exclamation margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "คุณไม่ควรป้อนข้อมูลที่ละเอียดอ่อนบนเว็บไซต์นี้"
                : "You should not enter any sensitive information on this website."}
            </p>
            <p>
              <i className="fa fa-circle-user margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "คุณไม่ควรป้อนข้อมูลส่วนบุคคลใด ๆ บนเว็บไซต์นี้"
                : "You should not enter any personal information on this website."}
            </p>
            <p>
              <i className="fa fa-keyboard margin-right-10px font-size-20px ap-c"></i>
              {language === "th"
                ? "คุณไม่ได้รับอนุญาตให้สแปมบนเว็บไซต์นี้"
                : "You are not allowed to spam on this website."}
            </p>
          </div>
        </div>
        <h1 className="text-left">
          *
          {language === "th"
            ? "ข้อกำหนดนี้อาจมีการเปลี่ยนแปลง"
            : "This terms is subject to change."}
        </h1>
      </div>
    </>
  );
}

export default Terms;
