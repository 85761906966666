// src/App.js (or parent component)
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Import BrowserRouter and other components from react-router-dom

import { LanguageProvider } from "./functions/LanguageContext";

// <-- Pages --> //

import Home from "./components/Home";
import Links from "./components/Links.js";
import PrivacyPolicy from "./components/privacy";
import Blogs from "./components/Blogs";
import YorWorTubGep19 from "./components/YorWorTubGep19";
import Countdown from "./components/Birthday";
import MBD from "./components/MBD";
import Login from "./components/Login";
import Feedback from "./components/Feedback";
import Terms from "./components/Terms";
import Search from "./components/Search";
import NotFound from "./components/NotFound";

// <-- Posts --> //

import PostsDetail1 from "./components/PostsDetail1";

// <-- Components --> //

import NavBar from "./components/Navbar.js";
import Footer from "./components/Footer";

// <-- CSS --> //

import "./App.css";
import "./css/appearance.css";
import "./css/feedback.css";
import "./css/font-awesome.css";
import "./css/font-awesome.min.css";
import "./css/footer.css";
import "./css/ico-moon.css";
import "./css/language.css";
import "./css/light-mode.css";
import "./css/login.css";
import "./css/main.css";
import "./css/NavBar.css";
import "./css/privacy.css";
import "./css/search.css";
import "./css/theme.css";
import "./css/violet-mode.css";
import JBD from "./components/JBD";

function App() {
  return (
    <>
      <LanguageProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/links" element={<Links />} />
            <Route path="/posts" element={<Blogs />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/posts/detail/1" element={<PostsDetail1 />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/search" element={<Search />} />
            <Route path="/auth/login" element={<Login />} />
            <Route
              path="/secret/school/yorwor/gep19"
              element={<YorWorTubGep19 />}
            />
            <Route path="/birthday" element={<Countdown />} />
            <Route path="/mannez/birthday" element={<MBD />} />
            <Route path="/jayjay/birthday" element={<JBD />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </LanguageProvider>
    </>
  );
}

export default App;
