import React, { useContext } from "react";
import { formatDistanceToNow } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import thLocate from "date-fns/locale/th";
import defaultLocate from "date-fns/locale/en-US";
import { LanguageContext } from "./LanguageContext";

const Timestamp = ({ epochTimestamp, timezone }) => {
  const { language } = useContext(LanguageContext);

  // Convert the epoch timestamp to a JavaScript Date object
  const date = new Date(epochTimestamp * 1000);

  // Convert to the desired timezone
  const zonedDate = utcToZonedTime(date, timezone);

  // Format the distance to now in a human-readable format
  const timeAgo = formatDistanceToNow(zonedDate, {
    addSuffix: true,
    locale: language === "th" ? thLocate : defaultLocate,
  });

  return <span>{timeAgo}</span>;
};

export default Timestamp;
