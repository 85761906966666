import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import LoginForm from "./LoginForm";
import { LanguageContext } from "../functions/LanguageContext";

function Login() {
  const [user, setUser] = useState(null);

  const { language } = useContext(LanguageContext);

  const handleLogin = (username) => {
    setUser(username);
  };
  return (
    <>
      <Helmet>
        <meta property="description" content="หน้าล็อกอิน (Rickroll Prank)" />
        <title>
          {language === "th" ? "ลงชื่อเข้าใช้" : "Login"} - CuteBear
        </title>
      </Helmet>
      <div className="container">
        {!user ? (
          <>
            <h2>{language === "th" ? "ลงชื่อเข้าใช้" : "Login"}</h2>
            <LoginForm onLogin={handleLogin} />
          </>
        ) : (
          <>
            <h2>Welcome, {user}!</h2>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </>
        )}
      </div>
    </>
  );
}

export default Login;
