import React from "react";
import { NavLink } from "react-router-dom";
import Timestamp from "../functions/Timestamp";

const BlogsPost = ({ date, title, image, url, desc, timestamp }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <NavLink className={`blogs-post`} to={url} onClick={scrollToTop}>
      <img className="blogs-img" src={image} alt={title} />
      <h3 className="blogs-date">
        <i className="fa fa-calendar margin-right-5px"></i>
        {date} (<Timestamp epochTimestamp={timestamp} timezone="Asia/Bangkok" />
        )
      </h3>
      <h2 className="blogs-title">{title}</h2>
      <p className="blogs-desc">{desc}</p>
    </NavLink>
  );
};

export default BlogsPost;
