// ThemeProvider.js
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import ThemeContext from "./ThemeContext"; // Import the ThemeContext

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    const savedTheme = Cookies.get("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const changeTheme = (selectedTheme) => {
    setTheme(selectedTheme);
    Cookies.set("theme", selectedTheme, { expires: 14 });
  };

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    Cookies.set("theme", newTheme, { expires: 14 }); // Set the theme in a cookie that expires in 14 days
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
