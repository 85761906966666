// NotFound.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import GoBackButton from "../functions/GoBackButton.js";
import { LanguageContext } from "../functions/LanguageContext";

function NotFound() {
  const { language } = useContext(LanguageContext);
  return (
    <>
      <Helmet>
        <title>404 - CuteBear</title>
      </Helmet>
      <div className="container">
        <img
          src="http://api.cutebear.in.th/v1/image?name=not-found"
          alt="404"
          className="not-found-img"
        />
        <h1 className="text-center">404 - Not Found</h1>
        <p className="text-center margin-bottom-20px">
          {language === "th"
            ? "ไม่พบหน้าที่คุณกำลังมองหา"
            : "The page you are looking for does not exist."}
        </p>
        <GoBackButton />
      </div>
    </>
  );
}

export default NotFound;
