import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import GamesPost from "../functions/GamesPosts";
import SocialPost from "../functions/SocialPosts";
import RobloxProfile from "../functions/RobloxProfile";
/* import IntroGrid from "../functions/IntroGrid"; */

function Home() {
  const { language } = useContext(LanguageContext);

  /*const Introduces = [
    {
      id: 1,
      thTitle: "ชื่อเล่น",
      title: "My Nickname",
      thDesc: "ไม่บอกจ้า 🤫",
      desc: "Secret 🤫",
    },
    {
      id: 2,
      thTitle: "เกิดวันที่",
      title: "My Birthday",
      thDesc: "วันพฤหัสบดีที่ 29 กรกฎาคม พ.ศ. 2553",
      desc: "Thurday, July 29, 2010",
    },
    {
      id: 3,
      thTitle: "อาศัยอยู่ที่",
      title: "Live at",
      thDesc: "สงขลา, ประเทศไทย",
      desc: "Songkhla, Thailand",
    },
  ];*/

  const FavRobloxGames = [
    {
      title: "Pet Simulator 99! 🐾",
      image: "https://api.cutebear.in.th/v1/image?name=ps99",
      isVerified: true,
      ownerLink: "https://www.roblox.com/groups/3959677",
      owner: "BIG Games Pets",
      link: "https://roblox.com/games/8737899170/",
    },
    {
      title: "Pet Simulator X! 🐾",
      image: "https://api.cutebear.in.th/v1/image?name=psx",
      isVerified: true,
      ownerLink: "https://www.roblox.com/groups/3959677",
      owner: "BIG Games Pets",
      link: "https://www.roblox.com/games/6284583030/",
    },
    {
      title: "Adopt Me! 🐶",
      image: "https://api.cutebear.in.th/v1/image?name=adoptme",
      isVerified: true,
      ownerLink: "https://www.roblox.com/groups/295182",
      owner: "Uplift Games",
      link: "https://www.roblox.com/games/920587237/",
    },
    {
      title: "Murder Mystery 2 🔪",
      image: "https://api.cutebear.in.th/v1/image?name=mm2",
      isVerified: false,
      ownerLink: "https://www.roblox.com/users/1848960/profile/",
      owner: "@Nikilis",
      link: "https://www.roblox.com/games/142823291/",
    },
    {
      title: "Brookhaven 🏡RP",
      image: "https://api.cutebear.in.th/v1/image?name=brookhaven-rp",
      isVerified: false,
      ownerLink: "https://www.roblox.com/users/60596019/profile/",
      owner: "@Wolfpaq",
      link: "https://www.roblox.com/games/4924922222/",
    },
    {
      title: "3008 [IKEA]",
      image: "https://api.cutebear.in.th/v1/image?name=3008",
      isVerified: true,
      ownerLink: "https://www.roblox.com/users/22718068/profile/",
      owner: "@uglyburger0",
      link: "https://www.roblox.com/games/2768379856/",
    },
    {
      title: "My Hello Kitty Cafe! ☕",
      image: "https://api.cutebear.in.th/v1/image?name=mhkc",
      isVerified: false,
      ownerLink: "https://www.roblox.com/groups/8252064",
      owner: "Rock Panda Games",
      link: "https://www.roblox.com/games/9346039031/",
    },
    {
      title: "👨‍🍳✨ My Restaurant!",
      image: "https://api.cutebear.in.th/v1/image?name=my-restaurant",
      isVerified: false,
      ownerLink: "https://www.roblox.com/groups/2703304",
      owner: "BIG Games™",
      link: "https://www.roblox.com/games/4490140733/",
    },
    {
      title: "Natural Disaster Survival",
      image:
        "https://api.cutebear.in.th/v1/image?name=natural-disaster-survival",
      isVerified: true,
      ownerLink: "https://www.roblox.com/users/80254/profile/",
      owner: "@Stickmasterluke",
      link: "https://www.roblox.com/games/189707/",
    },
  ];

  const SocialInfo = [
    {
      social: "tiktok",
      socialName: "TikTok",
      link: "https://www.tiktok.com/@socute_whitebear",
    },
    {
      social: "x-twitter",
      socialName: "X",
      link: "https://x.com/socutewhitebear",
    },
    {
      social: "instagram",
      socialName: "Instagram",
      link: "https://www.instagram.com/socutewhitebear/",
    },
    {
      social: "discord",
      socialName: "Discord",
      link: "https://discord.gg/FUXtCdV7t2",
    },
  ];
  const RobloxProfiles = [
    {
      displayName: "Cutebear",
      username: "gahuuuu",
      image:
        "https://api.cutebear.in.th/v1/image?name=cutebear-roblox-avatar",
      link: "https://www.roblox.com/users/757251905/profile",
      timestamp: 1536512400,
      joinDateTh: "10 ก.ย. 2561",
      joinDateEn: "Sep 10, 2018",
    },
    {
      displayName: "swnxk",
      username: "sweetdrem14y",
      image: "https://api.cutebear.in.th/v1/image?name=swnxk-roblox-avatar",
      link: "https://www.roblox.com/users/2767030054/profile",
      timestamp: 1627578000,
      joinDateTh: "30 ก.ค. 2564",
      joinDateEn: "Jul 30, 2021",
    },
    {
      displayName: "gahuuuuCutebear",
      username: "gahuuuu_Return",
      image:
        "https://api.cutebear.in.th/v1/image?name=gahuuuu_Return-roblox-avatar",
      link: "https://www.roblox.com/users/2783290566/profile",
      timestamp: 1627578000,
      joinDateTh: "5 ส.ค. 2564",
      joinDateEn: "Aug 5, 2021",
    },
  ];
  return (
    <>
      <Helmet>
        <title>CuteBear</title>
        <meta
          name="description"
          content="สวัสดีทุกคน ยินดีต้อนรับสู่หน้าหลักของ CuteBear! เว็บไซต์ของ TikToker คนหนึ่งที่อยากต้องการมีเว็บไซต์เป็นตัวเอง..."
        />
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
      </Helmet>
      <div className="container">
        <h2 className="text-left title">
          {language === "th" ? "หน้าหลัก" : "Home"}
        </h2>
        <h3 className="text-left sub-title">
          {language === "th"
            ? "ยินดีต้อนรับสู่หน้าหลักของ CuteBear!"
            : "Welcome to CuteBear's home page!"}
        </h3>
        <div className="divide-line t-1"></div>
        {/*`<div className="blogs-grid">
          {Introduces.map((post, index) => (
            <IntroGrid
              key={post.id}
              title={language === "th" ? post.thTitle : post.title}
              desc={language === "th" ? post.thDesc : post.desc}
            />
          ))}
          </div>`*/}
        <h3 id="my-fav-games" className="game-title text-center f-size-19">
          {language === "th"
            ? "เกมที่เราชอบเล่นใน Roblox มีดังนี้!"
            : "These are my favorite Roblox games!"}
        </h3>
        <div className="games-grid">
          {FavRobloxGames.map((post, index) => (
            <GamesPost
              key={index}
              title={post.title}
              ownerLink={post.ownerLink}
              owner={post.owner}
              isVerified={post.isVerified}
              image={post.image}
              link={post.link}
            />
          ))}
        </div>
        <span id="social" class="ivi-s"></span>
        <div className="divide-line t-1"></div>
        <h3 className="text-center f-size-19">
          {language === "th" ? "สามารถติดตามเราได้ทาง" : "You can follow us on"}
        </h3>
        <div className="social-grid">
          {SocialInfo.map((post, index) => (
            <SocialPost
              key={index}
              username={post.username}
              image={post.image}
              social={post.social}
              socialName={post.socialName}
              link={post.link}
            />
          ))}
        </div>
        <span id="roblox-profile" class="ivi-s"></span>
        <div className="divide-line t-1"></div>
        <h3 className="text-center f-size-19">
          {language === "th" ? "โปรไฟล์ Roblox ของเรา!" : "My Roblox Profile!"}
        </h3>
        <div className="roblox-profile-grid">
          {RobloxProfiles.map((pf, index) => (
            <RobloxProfile
              key={index}
              displayName={pf.displayName}
              username={pf.username}
              image={pf.image}
              link={pf.link}
              /* timestamp={pf.timestamp}
            joinDateTh={pf.joinDateTh}
            joinDateEn={pf.joinDateEn} */
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Home;
