// src/components/Links.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";

function Links() {
  const { language } = useContext(LanguageContext);
  return (
    <>
      <Helmet>
        <meta property="description" content="แหล่งรวมลิงก์ต่างๆทั้งหลาย🤍" />
        <title>{language === "th" ? "ลิงก์" : "Links"} - CuteBear</title>
      </Helmet>
      <div className="container">
        <div className="background-image">
          <img
            src="https://api.cutebear.in.th/v1/image?name=hbd-bg"
            alt=""
          />
        </div>
        <div className="image-container">
          <img
            src="https://api.cutebear.in.th/v1/image?name=CuteBear-icon"
            alt="CuteBear Avatar"
          />
        </div>
        <h1 className="username-link">@socute_whitebear</h1>
        <div className="desc-channel">แหล่งรวมลิงก์ต่างๆทั้งหลาย🤍</div>

        <div className="sub-container">
          <a
            className="sub-link"
            href="https://www.roblox.com/users/757251905/profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="sub-icon"
              src="https://api.cutebear.in.th/v1/image?name=roblox"
              title="Roblox Profile"
              alt="Roblox Profile"
            />
          </a>
          <a
            className="sub-link"
            href="https://x.com/socutewhitebear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="sub-icon"
              src="https://api.cutebear.in.th/v1/image?name=x-twitter"
              title="X Profile"
              alt="X Profile"
            />
          </a>
          <a
            className="sub-link"
            href="https://www.tiktok.com/@socute_whitebear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="sub-icon"
              src="https://api.cutebear.in.th/v1/image?name=tiktok"
              title="TikTok Profile"
              alt="TikTok Profile"
            />
          </a>
          <a
            className="sub-link"
            href="https://discord.gg/FUXtCdV7t2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="sub-icon"
              src="https://api.cutebear.in.th/v1/image?name=discord"
              title="Discord Server"
              alt="Discord Server"
            />
          </a>
        </div>
        <a
          className="tile"
          href="../"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=globe"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Main Website</p>
          <div
            className="tile-share-button"
            link="https://www.socutewhitebear.site/"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://www.roblox.com/users/757251905/profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=roblox"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Roblox - Main Profile</p>
          <div
            className="tile-share-button"
            link="https://www.roblox.com/users/757251905/profile"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://www.roblox.com/users/2767030054/profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=roblox"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Roblox - 2nd Profile</p>
          <div
            className="tile-share-button"
            link="https://www.roblox.com/users/2767030054/profile"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://open.spotify.com/user/tnpijfincwbdr9xadplbn2jmy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=spotify"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Spotify</p>
          <div
            className="tile-share-button"
            link="https://open.spotify.com/user/tnpijfincwbdr9xadplbn2jmy"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://www.tiktok.com/@socute_whitebear"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=tiktok"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>TikTok</p>
          <div
            className="tile-share-button"
            link="https://www.tiktok.com/@socute_whitebear"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://x.com/socutewhitebear"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=x-twitter"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>X</p>
          <div
            className="tile-share-button"
            link="https://twitter.com/socutewhitebear"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://www.instagram.com/socutewhitebear/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=ig"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Instagram</p>
          <div
            className="tile-share-button"
            link="https://www.instagram.com/socutewhitebear/"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://discord.gg/FUXtCdV7t2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=discord"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Discord Server</p>
          <div
            className="tile-share-button"
            link="https://discord.gg/FUXtCdV7t2"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://www.pinterest.com/socute_whitebear/?utm_medium=social&utm_source=instabio&utm_campaign=Pinterest"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=pinterest"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>Pinterest</p>
          <div
            className="tile-share-button"
            link="https://www.pinterest.com/socute_whitebear/?utm_medium=social&utm_source=instabio&utm_campaign=Pinterest"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
        <a
          className="tile"
          href="https://user.zepeto.me/socute_whitebear?referrer=socute_whitebear"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon">
            <img
              src="https://api.cutebear.in.th/v1/image?name=zepeto"
              className="rounded-circle"
              alt=""
            />
          </div>
          <p>ZEPETO</p>
          <div
            className="tile-share-button"
            link="https://user.zepeto.me/socute_whitebear?referrer=socute_whitebear"
          >
            <i className="fa fa-arrow-up-right-from-square"></i>
          </div>
        </a>
      </div>
    </>
  );
}

export default Links;
