// ThemeProvider.js
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import AppearanceContext from "./AppearanceContext"; // Import the ThemeContext

const AppearanceProvider = ({ children }) => {
  const [appearance, setAppearance] = useState("default");
  const isSpecial = true;
  const isSpeciala = false;

  useEffect(() => {
    const savedAppearance = Cookies.get("appearance");
    if (savedAppearance) {
      setAppearance(savedAppearance);
    }
  }, []);

  const changeAppearance = (selectedAppearance) => {
    setAppearance(selectedAppearance);
    Cookies.set("appearance", selectedAppearance, { expires: 14 });
  };

  const toggleAppearance = () => {
    let newAppearance = appearance;
    if (appearance === "default" && isSpecial) {
      newAppearance = "violet";
    } else if (appearance === "default" && isSpeciala) {
      newAppearance = "sky";
    } else {
      newAppearance = "default";
    }

    setAppearance(newAppearance);
    Cookies.set("appearance", newAppearance, { expires: 14 }); // Set the theme in a cookie that expires in 14 days
  };

  return (
    <AppearanceContext.Provider
      value={{ appearance, toggleAppearance, changeAppearance }}
    >
      {children}
    </AppearanceContext.Provider>
  );
};

export default AppearanceProvider;
