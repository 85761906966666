import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import LanguageDropdown from "../functions/LanguageButton";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { language } = useContext(LanguageContext);

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/312621a761.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <footer className="footer">
        <div className="divide-line center t-1 w-full margin-bottom-15px"></div>
        <div className="left-column">
          <div className="menu-bar-brand-footer">
            <div className="menu-bar-brand-item-footer">
              <img
                src="https://api.cutebear.in.th/v1/image?name=CuteBear-NavIcon"
                className="menu-bar-icon-footer"
                alt=""
              />
              <span className="menu-bar-text-footer">
                Cute
                <span className="menu-bar-text-hl-footer">Bear</span>
              </span>
            </div>
          </div>
          <LanguageDropdown />
        </div>
        <div className="right-column">
          <div className="links">
            <h3>{language === "th" ? "โซเชียล" : "Social"}</h3>
            <ul className="ulink">
              <li className="link">
                <a
                  href="https://tiktok.com/@socute_whitebear"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fab fa-tiktok margin-right-5px"></i>
                  <span>TikTok</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://discord.gg/FUXtCdV7t2"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fab fa-discord margin-right-5px"></i>
                  <span>Discord</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://www.instagram.com/socutewhitebear/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fab fa-instagram margin-right-5px"></i>
                  <span>Instagram</span>
                </a>
              </li>
              <li className="links">
                <a
                  href="https://x.com/socutewhitebear"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fab fa-x-twitter margin-right-5px"></i>
                  <span>X (Twitter)</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="links">
            <h3>{language === "th" ? "เกมส์" : "Games"}</h3>
            <ul className="ulink">
              <li className="link">
                <a
                  href="https://www.roblox.com/users/757251905/profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="icon-wf-roblox margin-right-5px"></i>
                  <span>Roblox</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://user.zepeto.me/socute_whitebear?referrer=socute_whitebear"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="icon-wf-zepeto margin-right-5px"></i>
                  <span>ZEPETO</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="links">
            <h3>{language === "th" ? "เกี่ยวกับเรา" : "About Us"}</h3>
            <ul className="ulink">
              <li className="link">
                <NavLink to="/privacy" className="link-a" onClick={scrollToTop}>
                  <i className="fa fa-shield-halved margin-right-5px"></i>
                  <span>
                    {language === "th" ? "ความเป็นส่วนตัว" : "Privacy"}
                  </span>
                </NavLink>
              </li>
              <li className="link">
                <NavLink to="/terms" className="link-a" onClick={scrollToTop}>
                  <i className="fa fa-compass margin-right-5px"></i>
                  <span>{language === "th" ? "ข้อกำหนด" : "Terms"}</span>
                </NavLink>
              </li>
              <li className="link">
                <NavLink
                  to="/feedback"
                  className="link-a"
                  onClick={scrollToTop}
                >
                  <i className="fa fa-comment margin-right-5px"></i>
                  <span>{language === "th" ? "ข้อเสนอแนะ" : "Feedback"}</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="links">
            <h3>{language === "th" ? "พันธมิตร" : "Partners"}</h3>
            <ul className="ulink">
              <li className="link">
                <a
                  href="https://mannez.site"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fa fa-globe margin-right-5px"></i>
                  <span>Jittiphat Somsai</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://mori-music.mannez.site/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fa fa-globe margin-right-5px"></i>
                  <span>Mori Music</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://daisy.starnextstudios.cloud/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fa fa-globe margin-right-5px"></i>
                  <span>Daisy!</span>
                </a>
              </li>
              <li className="link">
                <a
                  href="https://info.jayjay.space"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-a"
                >
                  <i className="fa fa-globe margin-right-5px"></i>
                  <span>JayJay</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright-text-container">
          <p className="copyright-text">
            {language === "th"
              ? `© ${new Date().getFullYear()} cutebear.in.th.`
              : `© ${new Date().getFullYear()} cutebear.in.th.`}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
