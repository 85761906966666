import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext";

const RobloxProfile = ({ displayName, username, image, link }) => {
  const { language } = useContext(LanguageContext);
  return (
    <div className={`roblox-container`}>
      <img
        className="roblox-pfp"
        src={image}
        alt={`${displayName}'s Roblox Avatar`}
      />
      <span className="roblox-displayname">{displayName}</span>
      <span className="roblox-username">@{username}</span>
      <br />
      <a
        className="view-roblox-pf"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {language === "th" ? "ดูโปรไฟล์ Roblox" : "View Roblox Profile"}
        <i className="fa fa-arrow-up-right-from-square margin-left-10px"></i>
      </a>
    </div>
  );
};

export default RobloxProfile;
