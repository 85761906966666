import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import Cookies from "js-cookie";
import DiscordService from "../functions/DiscordService";
import Timestamp from "../functions/Timestamp";

const Feedback = () => {
  const [lastFeedbackTime, setLastFeedbackTime] = useState(
    Cookies.get("__la_ft")
  );
  const lastFeedbackTimestamp = (lastFeedbackTime / 1000 + 86400).toFixed();
  const { language } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);

  const [truncated, setTruncated] = useState(true);

  const toggleTruncated = () => {
    setTruncated(!truncated);
  };

  const [username, setUsername] = useState("" || Cookies.get("__na"));
  const [email, setEmail] = useState("" || Cookies.get("__el"));
  const [description, setDescription] = useState("" || Cookies.get("__de_n"));

  const [formSubmitted, setFormSubmitted] = useState(
    null || Cookies.get("__f_st")
  );
  const [isError, setIsError] = useState(false);

  const [formData, setFormdata] = useState({
    data: {
      Username: "",
      Email: "",
      Description: "",
    },
    error: {},
  });

  const setDynamicFormData = (name, value) => {
    setFormdata({
      data: {
        ...formData.data,
        [name]: value,
      },
      error: {},
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const { Send } = DiscordService();

  const PostToDiscord = () => {
    const description = Object.entries(formData.data)
      .map((d) => `${d[0]}: ${d[1]}`)
      .join("\n");

    Send(description);
  };

  const sendFeedback = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    PostToDiscord();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (lastFeedbackTime) {
      const currentTime = new Date().getTime();
      const lastTime = parseInt(lastFeedbackTime);
      const timeDifference = currentTime - lastTime;

      if (timeDifference < 24 * 60 * 60 * 1000) {
        return;
      }
    }

    if (
      formData.data.Username &&
      formData.data.Email &&
      formData.data.Description &&
      isValidEmail(formData.data.Email)
    ) {
      // Perform form submission logic here
      console.log("Form submitted:", formData);

      setIsLoading(true);

      setUsername(formData.data.Username);
      setEmail(formData.data.Email);
      setDescription(formData.data.Description);

      const currentTime = new Date().getTime();
      setLastFeedbackTime(currentTime.toString());

      Cookies.set("__la_ft", currentTime.toString(), { expires: 1 });
      Cookies.set("__na", formData.data.Username, { expires: 1 });
      Cookies.set("__el", formData.data.Email, { expires: 1 });
      Cookies.set("__de_n", formData.data.Description, { expires: 1 });

      await sendFeedback();

      setIsLoading(false);

      setFormSubmitted(true);
      Cookies.set("__f_st", formSubmitted, { expires: 1 });
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {language === "th" ? "ข้อเสนอแนะ" : "Feedback"} - CuteBear
        </title>
      </Helmet>
      <div className="container">
        {!formSubmitted ? (
          <>
            <h2>{language === "th" ? "ข้อเสนอแนะ" : "Feedback"}</h2>
            <form noValidate className="feedback-form" onSubmit={handleSubmit}>
              <span className="f-size-19 margin-bottom-5px bold">
                {language === "th" ? "ชื่อผู้ใช้งาน" : "Username"}
              </span>
              <input
                type="text"
                name="Username"
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                maxLength={20}
                disabled={isLoading ? true : false}
                className={`${
                  isError && !formData.data.Username
                    ? "error-form-input"
                    : "form-input"
                } ${isLoading ? "disabled" : ""}`}
                placeholder={`${
                  language === "th" ? "ชื่อผู้ใช้งาน" : "Username"
                }*`}
                minLength={3}
                onChange={(e) => {
                  const { name, value } = e.target;

                  setDynamicFormData(name, value);
                }}
              />
              {isError && !formData.data.Username && (
                <div className="form-error-message">
                  {language === "th"
                    ? "ต้องกรอกข้อมูลในช่องนี้"
                    : "Must fill this field"}
                  *
                </div>
              )}
              <span className="f-size-19 margin-bottom-5px bold">
                {language === "th" ? "อีเมล" : "Email"}
              </span>
              <input
                name="Email"
                type="email"
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                disabled={isLoading ? true : false}
                className={`
                  ${
                    isError &&
                    (!formData.data.Email || !isValidEmail(formData.data.Email))
                      ? "error-form-input"
                      : "form-input"
                  } ${isLoading ? "disabled" : ""}`}
                placeholder={`${language === "th" ? "อีเมล" : "Email"}*`}
                minLength={3}
                onChange={(e) => {
                  const { name, value } = e.target;

                  setDynamicFormData(name, value);
                }}
              />
              {isError && !formData.data.Email && (
                <div className="form-error-message">
                  {language === "th"
                    ? "ต้องกรอกข้อมูลในช่องนี้"
                    : "Must fill this field"}
                  *
                </div>
              )}
              {isError &&
                formData.data.Email &&
                !isValidEmail(formData.data.Email) && (
                  <div className="form-error-message">
                    {language === "th"
                      ? "ที่อยู่อีเมลที่ไม่ถูกต้อง"
                      : "Invalid email address"}
                    *
                  </div>
                )}
              <span className="f-size-19 margin-bottom-5px bold">
                {language === "th" ? "รายละเอียด" : "Description"}
              </span>
              <textarea
                type="text"
                name="Description"
                spellCheck="false"
                autoComplete="off"
                maxLength={1024}
                autoCorrect="off"
                autoCapitalize="off"
                disabled={isLoading ? true : false}
                placeholder={`${
                  language === "th" ? "รายละเอียด" : "Description"
                }*`}
                className={`${
                  isError && !formData.data.Description
                    ? "error-form-input"
                    : "form-input"
                } ${isLoading ? "disabled" : ""}
                `}
                cols="40"
                rows="5"
                onChange={(e) => {
                  const { name, value } = e.target;

                  setDynamicFormData(name, value);
                }}
              />
              {isError && !formData.data.Description && (
                <div className="form-error-message">
                  {language === "th"
                    ? "ต้องกรอกข้อมูลในช่องนี้"
                    : "Must fill this field"}
                  *
                </div>
              )}
              <button
                type="submit"
                disabled={isLoading ? true : false}
                class={isLoading ? "disabled" : ""}
              >
                {language === "th"
                  ? isLoading
                    ? "กำลังส่ง..."
                    : "ส่งคำแนะนำ"
                  : isLoading
                  ? "Sending..."
                  : "Submit Feedback"}
              </button>
            </form>
          </>
        ) : (
          <>
            <h2 className="text-left title">
              {language === "th"
                ? "ขอบคุณสำหรับข้อเสนอแนะของคุณ"
                : "Thank you for your feedback"}
            </h2>
            <h3 className="f-size-19 text-left sub-title">
              {language === "th"
                ? "ข้อเสนอแนะของคุณได้ถูกไปยังผู้ดูแลระบบ"
                : "Your feedback has been sent to the administrator."}
            </h3>
            <div className="feedback-items-container">
              <div className="text-items">
                <p>
                  <i className="fa fa-user margin-right-10px font-size-20px ap-c"></i>
                  {language === "th" ? "ชื่อผู้ใช้งาน" : "Username"}: {username}
                </p>
                <p>
                  <i className="fa fa-at margin-right-10px font-size-20px ap-c"></i>
                  {language === "th" ? "อีเมล" : "Email"}: {email}
                </p>
                <p className={`truncate-text ${truncated ? "truncated" : ""}`}>
                  <i className="fa fa-comment margin-right-10px font-size-20px ap-c"></i>
                  {language === "th" ? "รายละเอียด" : "Description"}:{" "}
                  {truncated
                    ? `${description.slice(0, 200)}...`
                    : `${description}...`}
                  {truncated ? (
                    <span className="read-more" onClick={toggleTruncated}>
                      Read more
                    </span>
                  ) : (
                    <span className="show-less" onClick={toggleTruncated}>
                      Show less
                    </span>
                  )}
                </p>
              </div>
            </div>
            <h1 className="text-left">
              *
              {language === "th"
                ? "คุณสามารถส่งข้อเสนอแนะอีกครั้งได้"
                : "You can submit feedback again "}
              <Timestamp
                epochTimestamp={lastFeedbackTimestamp}
                timezone={"Asia/Bangkok"}
              />
            </h1>
          </>
        )}
      </div>
    </>
  );
};

export default Feedback;
