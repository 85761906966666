import React, { createContext, useState, useEffect } from "react";
import { languageOptions, defaultLanguage } from "./LanguageOptions";
import Cookies from "js-cookie";

const LanguageContext = createContext();

const LanguageProvider = (props) => {
  const [language, setLanguage] = useState(defaultLanguage);

  useEffect(() => {
    const savedLanguage = Cookies.get("language");
    document.documentElement.lang = savedLanguage || defaultLanguage;

    /*
    const userLanguage = navigator.language;

    if (userLanguage === "th") {
      setLanguage("th");
      document.documentElement.lang = "th";
    } else {
      setLanguage("en");
      document.documentElement.lang = "en";
    }
    */

    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const changeLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    document.documentElement.lang = selectedLanguage;
    Cookies.set("language", selectedLanguage, { expires: 14 });
  };

  return (
    <LanguageContext.Provider
      value={{ language, languageOptions, changeLanguage }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
