// src/NavBar.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";
import { NavLink } from "react-router-dom";
import Timestamp from "../functions/Timestamp";

function PostsDetail1() {
  const { language } = useContext(LanguageContext);

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <>
      <Helmet>
        <meta
          property="description"
          content="July 22, 2023 - เพิ่มหน้าโพสต์ โพสต์จาก @socute_whitebear"
        />
        <title>
          {language === "th"
            ? "โพสต์: รูปลักษณ์ใหม่!"
            : "Posts: New Appearance!"}{" "}
          - CuteBear
        </title>
      </Helmet>
      <div className="container">
        <div className="text-left nav-page flex">
          <NavLink className="nav-page-link" to="/" onClick={scrollToTop}>
            Home
          </NavLink>
          <i className="fa fa-chevron-right chevron-link margin-left-10px margin-right-10px"></i>
          <NavLink className="nav-page-link" to="/posts" onClick={scrollToTop}>
            Posts
          </NavLink>
          <i className="fa fa-chevron-right chevron-link margin-left-10px margin-right-10px"></i>
          <NavLink className="nav-page-link cp-po" to="#" onClick={scrollToTop}>
            Detail
          </NavLink>
        </div>
        <span className="posts-detail-date f-size-16 text-left margin-top-25px">
          <i className="fa fa-calendar margin-right-5px"></i>
          {language === "th" ? "21 ส.ค. 2566" : "Aug 21, 2023"} (
          <Timestamp epochTimestamp={1692550800} timezone="Asia/Bangkok" />)
        </span>
        <h2 className="text-left title margin-top-10px">
          {language === "th" ? "รูปลักษณ์ใหม่!" : "New Appearance!"}
        </h2>
        <h3 className="text-left sub-title">
          {language === "th"
            ? "เว็บไซต์ได้รับการปรับปรุงรูปลักษณ์!"
            : "The website has been updated its appearance!"}
        </h3>
        <div className="divide-line t-1"></div>
        <h3 className="text-center in-sub-title">
          {language === "th" ? "หน้าหลัก" : "Home"}
        </h3>{" "}
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบเก่าจะมีหน้าตาแบบนี้:"
            : "The old one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-old-home-page"
          alt="Old Home Page"
        />
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบใหม่จะมีหน้าตาแบบนี้:"
            : "The new one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-new-home-page"
          alt="New Home Page"
        />
        <div className="divide-line t-1"></div>
        <h3 className="text-center in-sub-title">
          {language === "th" ? "หน้าโพสต์" : "The Posts"}
        </h3>{" "}
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบเก่าจะมีหน้าตาแบบนี้:"
            : "The old one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-old-posts-page"
          alt="Old Posts Page"
        />
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบใหม่จะมีหน้าตาแบบนี้:"
            : "The new one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-new-posts-page"
          alt="New Posts Page"
        />
        <div className="divide-line t-1"></div>
        <h3 className="text-center in-sub-title">
          {language === "th" ? "ความเป็นส่วนตัว" : "Privacy"}
        </h3>{" "}
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบเก่าจะมีหน้าตาแบบนี้:"
            : "The old one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-old-privacy-page"
          alt="Old Privacy Page"
        />
        <h3 className="text-center inner-sub-title">
          {language === "th"
            ? "แบบใหม่จะมีหน้าตาแบบนี้:"
            : "The new one will look like this:"}
        </h3>
        <img
          className="images-detail-landscape"
          src="https://api.cutebear.in.th/v1/image?name=detail-1-new-privacy-page"
          alt="New Privacy Page"
        />
        <h1 className="text-left">
          *
          {language === "th"
            ? "หากคุณมีข้อเสนอแนะ คุณสามารถส่งข้อเสนอแนะได้"
            : "If you have any suggestions. You can submit feedback"}{" "}
          <NavLink
            to="/feedback"
            className="name-title-a"
            onClick={scrollToTop}
          >
            {language === "th" ? "ที่นี่" : "here"}
          </NavLink>
          {language === "th" ? "" : "."}
        </h1>
      </div>
    </>
  );
}

export default PostsDetail1;
