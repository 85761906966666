import React, { useState, useRef, useEffect } from "react";

const GamesPost = ({ title, ownerLink, isVerified, owner, image, link }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const menuRef = useRef(null);
  const handleInfoMenuToggle = () => {
    setIsInfoOpen(!isInfoOpen);

    document.body.style.overflowY = isInfoOpen ? "auto" : "hidden";
    document.body.style.overflowX = "hidden";
  };

  const setIsInfoOpenRef = async (input) => {
    await new Promise((resolve) => setTimeout(resolve, 90));
    setIsInfoOpen(input);
    document.body.style.overflowY = isInfoOpen ? "auto" : "hidden";
    document.body.style.overflowX = "hidden";
  };

  const handleClickOutside = async (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      await setIsInfoOpenRef(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div className={`games-post`}>
      <img className="games-img" src={image} alt={title} title={title} />
      <a
        className="games-title"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
      <div class="games-owner-container">
        <span class="game-owner-by">By</span>
        <a
          className="game-owner-bg"
          href={ownerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {owner}
        </a>
        {isVerified === true ? (
          <>
            <button
              className="verified-badge-btn"
              onClick={handleInfoMenuToggle}
              type="button"
            >
              <img
                className="verified-badge-icon"
                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cg clip-path='url(%23clip0_8_46)'%3E%3Crect x='5.88818' width='22.89' height='22.89' transform='rotate(15 5.88818 0)' fill='%230066FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.543 8.7508L20.549 8.7568C21.15 9.3578 21.15 10.3318 20.549 10.9328L11.817 19.6648L7.45 15.2968C6.85 14.6958 6.85 13.7218 7.45 13.1218L7.457 13.1148C8.058 12.5138 9.031 12.5138 9.633 13.1148L11.817 15.2998L18.367 8.7508C18.968 8.1498 19.942 8.1498 20.543 8.7508Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8_46'%3E%3Crect width='28' height='28' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"
                title="Verified Badge Icon"
                alt="Verified Badge Icon"
              ></img>
            </button>
            {isInfoOpen && (
              <>
                <div className={`v-info-overlay-container`}>
                  <div className="v-info-menu" ref={menuRef}>
                    <button
                      class="v-info-close-btn"
                      onClick={handleInfoMenuToggle}
                      aria-label="Close"
                    >
                      <i class="fa fa-close"></i>
                    </button>
                    <h2 class="v-info-title">Verified Badge</h2>
                    <div className="v-divide-line t-1"></div>
                    <img
                      className="v-info-verified-badge-icon"
                      src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cg clip-path='url(%23clip0_8_46)'%3E%3Crect x='5.88818' width='22.89' height='22.89' transform='rotate(15 5.88818 0)' fill='%230066FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.543 8.7508L20.549 8.7568C21.15 9.3578 21.15 10.3318 20.549 10.9328L11.817 19.6648L7.45 15.2968C6.85 14.6958 6.85 13.7218 7.45 13.1218L7.457 13.1148C8.058 12.5138 9.031 12.5138 9.633 13.1148L11.817 15.2998L18.367 8.7508C18.968 8.1498 19.942 8.1498 20.543 8.7508Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8_46'%3E%3Crect width='28' height='28' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"
                      title="Verified Badge Icon"
                      alt="Verified Badge Icon"
                    ></img>
                    <span className="v-info-desc">
                      This badge verifies that the holder is a notable and
                      authentic creator, brand, or public figure.
                    </span>
                    <div className="v-info-btn-container">
                      <a
                        href="https://en.help.roblox.com/hc/articles/7997207259156"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn More
                      </a>
                      <button onClick={handleInfoMenuToggle}>Close</button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <br />
      <br />
      <a
        className="games-play-btn"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Play ${title}`}
      >
        <svg
          width="20px"
          height="20.3578893px"
          viewBox="0 0 14 14.3578893"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Blog"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Blog-Open-(1)"
              transform="translate(-1278.000000, -327.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <g id="Group-41" transform="translate(930.000000, 131.000000)">
                <g id="Group-24" transform="translate(0.000000, 174.500000)">
                  <g id="Group-25" transform="translate(320.000000, 13.500000)">
                    <path
                      d="M28,21.1115941 L28,9.24630166 C28,8.28815411 29.0373813,7.68943944 29.8670089,8.16878077 L42,15.1789541 L29.8670151,22.1890963 C29.0373813,22.6684563 28,22.0697541 28,21.1115941 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </div>
  );
};

export default GamesPost;
