import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../functions/LanguageContext";
import RightItems from "../functions/RightItems";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { language } = useContext(LanguageContext);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);

    document.body.style.overflowY = isMenuOpen ? "auto" : "hidden";
    document.body.style.overflowX = "hidden";
  };

  const handleMenuToggleOnLink = () => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);

    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
  };

  const scrollToSocial = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    window.location.href = "#social";
  };

  const handleMenuToggleOnLinkHash = async () => {
    setIsMenuOpen(false);

    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";

    await scrollToSocial();
  };

  /*
    const handleMenuToggleNoActive = () => {
      setIsMenuOpen((prev) => !prev);

      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
    };
    */

  return (
    <nav className={`nav-bar-container`}>
      <div className={`top-bar`}></div>
      <nav className={`nav-bar-container`}>
        <div class="left-items-container">
          <button
            className="check-menu-btn"
            onClick={handleMenuToggle}
            aria-label="Menu"
            type="button"
          >
            <div className="button-ico">
              <i className={`fa fa-${isMenuOpen ? "close" : "bars"}`}></i>
            </div>
          </button>
        </div>
        <div className="nav-bar-brand" href="/">
          <NavLink
            className="nav-bar-brand-item"
            to="/"
            exact={true}
            onClick={handleMenuToggleOnLink}
          >
            <img
              src="https://api.cutebear.in.th/v1/image?name=CuteBear-NavIcon"
              className="nav-bar-icon"
              alt=""
            />
            <span className="nav-bar-text">
              Cute
              <span className="nav-bar-text-hl">Bear</span>
            </span>
          </NavLink>
        </div>
        <div class="line-divide-brand"></div>
        {isMenuOpen ? (
          <div className="backdrop-blur" onClick={handleMenuToggle}></div>
        ) : (
          <></>
        )}
        <ul className={isMenuOpen ? "nav-links active" : "nav-links"}>
          <li>
            <NavLink
              to="/"
              exact={true}
              className="nav-link"
              onClick={handleMenuToggleOnLink}
            >
              <i className="fa fa-house margin-right-5px"></i>
              {language === "th" ? "หน้าหลัก" : "Home"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/links"
              className="nav-link"
              onClick={handleMenuToggleOnLink}
            >
              <i className="fa fa-link margin-right-5px"></i>
              {language === "th" ? "ลิงก์" : "Links"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/posts"
              className="nav-link"
              onClick={handleMenuToggleOnLink}
            >
              <i className="fa fa-newspaper margin-right-5px"></i>
              {language === "th" ? "โพสต์" : "Posts"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              className="nav-link-hash"
              onClick={handleMenuToggleOnLinkHash}
            >
              <i className="fa fa-rss margin-right-5px"></i>
              {language === "th" ? "โซเชียล" : "Social"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="https://www.tiktok.com/@socute_whitebear"
              className="nav-link"
              onClick={handleMenuToggleOnLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok margin-right-5px"></i>TikTok
              <i className="fa fa-arrow-up-right-from-square margin-left-10px"></i>
            </NavLink>
          </li>
        </ul>
        <div class="line-divide-brand-right"></div>
        <RightItems />
      </nav>
    </nav>
  );
};

export default NavBar;
