import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";

// Function to convert an epoch timestamp to milliseconds
function epochToMilliseconds(epoch) {
  return epoch * 1000;
}

function JBD() {
  const [timeRemaining, setTimeRemaining] = useState(0);

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    // Target epoch timestamp (Replace with your target epoch timestamp)
    const targetTimestamp = 1723093200;

    const updateCountdown = () => {
      const now = Date.now(); // Current timestamp in milliseconds
      const targetMilliseconds = epochToMilliseconds(targetTimestamp);
      const timeDiff = targetMilliseconds - now;

      if (timeDiff <= 0) {
        setTimeRemaining(0);
      } else {
        setTimeRemaining(timeDiff);
      }
    };

    // Update the countdown every second
    const interval = setInterval(updateCountdown, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Helper function to format the time in days, hours, minutes, and seconds
  const formatTime = (ms) => {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const hours = String(
      Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    ).padStart(2, "0");
    const minutes = String(
      Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))
    ).padStart(2, "0");
    const seconds = String(Math.floor((ms % (1000 * 60)) / 1000)).padStart(
      2,
      "0"
    );

    return `(${days} ${
      language === "th" ? "วัน" : "Days"
    }) ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <Helmet>
        <meta property="description" content="นับถอยหลังวันเกิดของ JayJay" />
        <title>
          {language === "th" ? "วันเกิดของ JayJay" : "JayJay's Birthday"} -
          CuteBear
        </title>
      </Helmet>
      <div className="container">
        <h2 className="text-left title">
          {language === "th"
            ? "นับถอยหลังวันเกิดของ"
            : `Birthday Countdown for`}{" "}
          <a
            className="name-title-a"
            href="https://jayjay.space"
            target="_blank"
            rel="noopener noreferrer"
          >
            JayJay
          </a>
        </h2>
        <span className="text-left margin-bottom-20px">
          (
          {language === "th"
            ? "วันอังคารที่ 8 สิงหาคม 2567"
            : "Tuesday, August 8, 2024"}{" "}
          12:00 {language === "th" ? "หลังเที่ยง" : "PM"} GMT+07:00)
        </span>
        {timeRemaining === 0 ? (
          <h3 className="text-left">
            {language === "th" ? "สุขสันต์วันเกิดน้าาา" : "Happy Birthday"}!!!
            🥳🎂🎉🎊
          </h3>
        ) : (
          <h3 className="text-left">{formatTime(timeRemaining)}</h3>
        )}
      </div>
    </>
  );
}

export default JBD;
