// BlogPost.js
import React from "react";

const SocialPost = ({ social, socialName, link }) => {
  return (
    <div className={`social-post`}>
      <a
        className="social-title"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {socialName}
      </a>
      <a
        className={`social-icon-btn ${social.toLowerCase()}`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={social}
      >
        <i className={`fab fa-${social.toLowerCase()}`}></i>
      </a>
    </div>
  );
};

export default SocialPost;
