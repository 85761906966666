import React from "react";

function YorWorTubGep19() {
  return (
    <div className="container">
      <h2 className="text-left title">1/9 คร่ะ ตามนั้นคือจบ</h2>
    </div>
  );
}

export default YorWorTubGep19;
