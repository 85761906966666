import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";

const Search = () => {
  const { language } = useContext(LanguageContext);

  const [query, setQuery] = useState("");

  const handleSearch = () => {
    if (query) {
      const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(
        query
      )}`;
      const newTab = window.open(searchUrl, "_blank", "noopener noreferrer");
      if (newTab) {
        newTab.focus(); // Ensure the new tab gains focus
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // Call the handleSearch function when Enter is pressed
    }
  };
  return (
    <>
      <Helmet>
        <title>{language === "th" ? "ค้นหา" : "Search"} - CuteBear</title>
      </Helmet>
      <div class="container">
        <h2 className="text-left title">
          {language === "th" ? "ค้นหา" : "Search"}
        </h2>
        <h3 className="text-left sub-title">
          {language === "th"
            ? "หน้าค้นหาของ Google (ทำขำ ๆ เฉย ๆ ไม่มีอะไรมาก)"
            : "Google search page (Just for fun, nothing more)"}
        </h3>
        <div className="divide-line t-1"></div>
        <div className="google-search-form">
          <input
            type="text"
            className="google-search-box"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={
              language === "th" ? "ค้นหาด้วย Google" : "Search on Google"
            }
            onKeyDown={handleKeyPress}
          />
          <button onClick={handleSearch} className="google-search-btn">
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;
