// src/NavBar.js
import React, { useContext, useState } from "react";
import BlogsPost from "../functions/BlogsPosts";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../functions/LanguageContext";

function Blogs() {
  const { language } = useContext(LanguageContext);
  const BlogsPosts = [
    {
      id: 3,
      date: "Aug 26, 2023",
      title: "Terms of Service Update",
      image: "https://api.cutebear.in.th/v1/image?name=terms-of-service",
      desc: "Check out the Terms of Service by clicking this post!",
      url: "/terms",
      timestamp: 1692982800,
      thDate: "26 ส.ค. 2566",
      thTitle: "การปรับปรุงเงื่อนไขการให้บริการ",
      thDesc: "ตรวจสอบเงื่อนไขการให้บริการโดยคลิกที่โพสต์นี้!",

      category: "Service",
    },
    {
      id: 2,
      date: "Aug 21, 2023",
      title: "New Appearance!",
      image: "https://api.cutebear.in.th/v1/image?name=theme",
      desc: "The website has been updated its appearance!",
      url: "/posts/detail/1",
      timestamp: 1692550800,
      thDate: "21 ส.ค. 2566",
      thTitle: "รูปลักษณ์ใหม่!",
      thDesc: "เว็บไซต์ได้รับการปรับปรุงรูปลักษณ์!",

      category: "Website",
    },
    {
      id: 1,
      date: "Aug 18, 2023",
      title: "Privacy Policy Update",
      image: "https://api.cutebear.in.th/v1/image?name=privacy-policy",
      desc: "Check out the Privacy Policy by clicking this post!",
      url: "/privacy",
      timestamp: 1692316800,
      thDate: "18 ส.ค. 2566",
      thTitle: "การปรับปรุงความเป็นส่วนตัว",
      thDesc: "ตรวจสอบนโยบายความเป็นส่วนตัวโดยคลิกที่โพสต์นี้!",

      category: "Service",
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("All");

  const categories = ["All", "Website", "Social", "Service", "Games", "Other"]; // Add more categories

  const filteredPosts =
    selectedCategory === "All"
      ? BlogsPosts
      : BlogsPosts.filter((post) => post.category === selectedCategory);
  return (
    <>
      <Helmet>
        <meta
          property="description"
          content="July 22, 2023 - เพิ่มหน้าโพสต์ โพสต์จาก @socute_whitebear"
        />
        <title>{language === "th" ? "โพสต์" : "Posts"} - CuteBear</title>
      </Helmet>
      <div className="container">
        <h2 className="text-left title">
          {language === "th" ? "โพสต์ต่าง ๆ" : "The Posts"}
        </h2>
        <h3 className="text-left sub-title">
          {language === "th" ? "โพสต์จาก" : "The posts from"}{" "}
          <a
            className="name-title-a"
            href="https://tiktok.com/@socute_whitebear"
            target="_blank"
            rel="noopener noreferrer"
          >
            @socute_whitebear
          </a>{" "}
          {language === "th"
            ? "บนติ๊กต๊อกจะขึ้นที่นี่"
            : "on TikTok will be show here."}
        </h3>
        <div className="divide-line t-1"></div>
        <div className="post-categories">
          {categories.map((category) => (
            <button
              className={`posts-category-btn ${
                category === selectedCategory ? "active-ctgr" : ""
              }`}
              onClick={(e) => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="blogs-grid">
          {filteredPosts.map((post) => (
            <BlogsPost
              key={post.id}
              date={language === "th" ? post.thDate : post.date}
              title={language === "th" ? post.thTitle : post.title}
              url={post.url}
              timestamp={post.timestamp}
              image={post.image}
              desc={language === "th" ? post.thDesc : post.desc}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Blogs;
