import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext";

const GoBackButton = () => {
  const { language } = useContext(LanguageContext);
  const goBack = () => {
    window.history.back();
  };

  return (
    <button className="back-btn" type="button" onClick={goBack}>
      <i className="fa fa-arrow-left"></i>{" "}
      {language === "th" ? "ย้อนกลับ" : "Go Back"}
    </button>
  );
};

export default GoBackButton;
