import React, { useState, useContext, useRef, useEffect } from "react";
import "../css/language.css";
import { LanguageContext } from "./LanguageContext";

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { language } = useContext(LanguageContext);
  const { changeLanguage } = useContext(LanguageContext);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleSelectDropdownTh = () => {
    changeLanguage("th");
  };

  const handleToggleSelectDropdownEn = () => {
    changeLanguage("en");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="language-selector-wrapper">
      <div
        className={`input-group-btn ${
          isOpen ? "relative" : ""
        } dropdown btn-group ${isOpen ? "open" : ""}`}
        ref={dropdownRef}
      >
        <button
          id="language-switcher"
          aria-haspopup="true"
          aria-expanded={isOpen}
          type="button"
          className={`input-dropdown-btn dropdown-toggle btn btn-default`}
          onClick={handleToggleDropdown}
        >
          <span className="lang-selection-label">
            <i className="fa fa-globe margin-right-5px"></i>{" "}
            {language === "th" ? "ภาษาไทย" : "English"}
          </span>
          <i className="fa fa-chevron-down"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="language-switcher">
          <li>
            <span
              class={language === "th" ? "language-active" : ""}
              role="menuitem"
              tabIndex="-1"
              onClick={handleToggleSelectDropdownTh}
            >
              ภาษาไทย
            </span>
          </li>
          <li>
            <span
              class={language === "en" ? "language-active" : ""}
              role="menuitem"
              tabIndex="-1"
              onClick={handleToggleSelectDropdownEn}
            >
              English
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LanguageDropdown;
